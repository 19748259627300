import { Detector } from "../utils/deviceDetector";

export default async ({ store }) => {
  const detect = new Detector(navigator.userAgent);

  const platform = (() => {
    switch (true) {
      case detect.phone():
        return 'mobile';
      case detect.tablet():
        return 'tablet';
      default:
        return 'desktop';
    }
  })();

  const mobilePlatform = (() => {
    switch (true) {
      case detect.ios():
        return 'ios';
      case detect.android():
        return 'android';
      default:
        return null;
    }
  })();

  store.commit('setPlatform', platform === 'desktop' ? 'desktop' : 'mobile');
  store.commit('setMobilePlatform', mobilePlatform);

};
